<template>
  <b-modal
    ref="modal-aufgabe-edit"
    auto-focus-button="ok"
    id="modalAufgabeEdit"
    title="Aufgabe erstellen"
    @hidden="resetFields"
    @show="onShow"
    data-bs-focus="false"
  >
    <b-overlay :show="isBusy">
      <b-form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="d-flex" style="gap: 8px">
          <b-form-group class="w-50" label="Bearbeiter/in" label-for="bearbeiter">
            <UsersMultiselect
              v-model="$v.form.bearbeiter.$model"
              :state="!$v.form.bearbeiter.$error ? true : null"
              :options="zugewiesenePersonOptions"
              :disabled="form.team !== null"
              style="border-radius: 8px"
              :borderColor="form.bearbeiter !== null ? '#1BC5BD' : '#E5EAEE'"
              :backgroundColor="form.team !== null ? '#ededed' : ''"
              :placeholder="'Wähle einen Bearbeiter...'"
            ></UsersMultiselect>
          </b-form-group>
          <b-button
            @click="form.bearbeiter = currentUserId"
            style="height: 38px"
            class="mt-8"
            size="sm"
            :variant="form.bearbeiter === currentUserId ? 'secondary' : 'primary'"
            :disabled="form.bearbeiter === currentUserId || form.team !== null"
          >
            Ich
          </b-button>
          <b-form-group class="w-50" label="Team" label-for="team">
            <b-form-select
              id="team"
              v-model="$v.form.team.$model"
              :state="!$v.form.team.$error ? true : null"
              :options="teamOptions"
              :disabled="form.bearbeiter !== null"
              :class="form.team === null && 'placeholder-multiselect'"
            >
              <template #first>
                <b-form-select-option
                  v-if="form.bearbeiter === null"
                  :value="null"
                  disabled
                  class="placeholder-multiselect"
                >
                  oder ein Team.
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <i
            v-if="form.bearbeiter !== null || form.team !== null"
            class="fas fa-times text-danger align-self-center"
            @click="resetPersonOderTeam"
            style="cursor: pointer"
          ></i>
        </div>
        <!-- Kategorie -->
        <div class="d-flex" style="gap: 8px">
          <b-form-group class="w-100" label="Kategorie" label-for="kategorie">
            <b-form-select
              id="kategorie"
              :class="form.kategorie === null && 'placeholder-multiselect'"
              v-model="$v.form.kategorie.$model"
              :state="!$v.form.kategorie.$error ? true : null"
              :options="kategorieOptions"
            >
              <template #first>
                <b-form-select-option v-if="form.kategorie === null" :value="null" disabled>
                  Wähle eine Kategorie...
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <i
            v-if="form.kategorie !== null"
            class="fas fa-times text-danger align-self-center"
            @click="resetKategorie"
            style="cursor: pointer"
          ></i>
        </div>
        <!-- fälligkeitsdatum -->
        <div class="d-flex" style="gap: 8px">
          <b-form-group
            class="w-100"
            label="Fälligkeitsdatum"
            label-for="datepicker"
            :invalid-feedback="
              $v.form.faelligkeitsdatum.$error && form.faelligkeitsdatum !== ''
                ? 'Please select a date in the future.'
                : ''
            "
          >
            <b-form-datepicker
              :min="minFaelligkeitsdatum"
              id="date-picker"
              :state="!$v.form.faelligkeitsdatum.$error ? true : null"
              v-model="$v.form.faelligkeitsdatum.$model"
              class="mb-2"
              placeholder="Wähle ein Datum..."
            ></b-form-datepicker>
          </b-form-group>
          <i
            v-if="form.faelligkeitsdatum !== null"
            class="fas fa-times text-danger align-self-center"
            @click="resetFaelligkeitsdatum"
            style="cursor: pointer"
          ></i>
        </div>
        <!-- Priorität -->
        <div class="d-flex" style="gap: 8px">
          <b-form-group label="Priorität" label-for="prioritaet" class="w-100">
            <b-form-select
              id="prioritaet"
              :class="form.prioritaet === null && 'placeholder-multiselect'"
              v-model="$v.form.prioritaet.$model"
              :state="!$v.form.prioritaet.$error ? true : null"
              :options="prioritaetOptions"
            >
              <template #first>
                <b-form-select-option v-if="form.prioritaet === null" :value="null" disabled>
                  Wähle eine Priorität...
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <i
            v-if="form.prioritaet !== null"
            class="fas fa-times text-danger align-self-center"
            @click="resetPrioritaet"
            style="cursor: pointer"
          ></i>
        </div>
        <!-- Beschreibung -->
        <b-form-group label="Beschreibung" label-for="beschreibung">
          <template slot="label">
            Beschreibung
            <i
              v-if="!isBeschreibungExpanded"
              class="fas fa-expand-arrows-alt float-right text-primary"
              style="cursor: pointer"
              @click="toggleBeschreibungsFeldSize"
              variant="primary"
            ></i>
          </template>
          <div :class="[{ 'beschreibung-expanded': isBeschreibungExpanded }]">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <span v-if="isBeschreibungExpanded" class="h4">Beschreibung</span>
              <i
                v-if="isBeschreibungExpanded"
                class="fas fa-compress-arrows-alt text-primary fa-lg"
                style="cursor: pointer"
                @click="toggleBeschreibungsFeldSize"
              ></i>
            </div>

            <Editor
              v-model="$v.form.beschreibung.$model"
              placeholder="Schreibe eine Beschreibung..."
              :disableMentions="true"
            />
          </div>
        </b-form-group>
        <!-- Verlinkungen -->
        <b-form-group label="Verlinkungen" label-for="verlinkungen">
          <div v-for="verlinkung in showVerlinkungen" :key="verlinkung.id" class="w-100 mb-2 d-flex">
            <b-form-select
              style="width: 40%"
              :class="verlinkung.typ === null && 'placeholder-multiselect'"
              id="verlinkungTyp"
              class="mr-3"
              @input="removeArtifacts(verlinkung.id)"
              v-model="verlinkung.typ"
              :disabled="fromFVC"
              :options="fromFVC ? ['Flugverfügbarkeit'] : ['Reisetermin', 'Vorgang', 'Reise']"
              :state="verlinkung.typ !== null"
            >
              <b-form-select-option v-if="verlinkung.typ === null" :value="null" disabled>
                Wähle einen Typ...
              </b-form-select-option>
            </b-form-select>
            <CustomMultiselect
              style="width: 40%"
              v-if="verlinkung.typ === 'Reise'"
              :class="
                verlinkung.artifactId === null ? 'invalid-custom-multiselect' : 'valid-custom-multiselect'
              "
              @input="changeArtifactReise($event, verlinkung.id)"
              :value="
                verlinkung.artifactId !== null
                  ? { kuerzelId: verlinkung.artifactId, reisekuerzel: verlinkung.artifactText }
                  : null
              "
              :show-labels="false"
              label="reisekuerzel"
              track-by="kuerzelId"
              :options="reisekuerzelOptions"
              placeholder="Reisesuche"
              removeClearButton
            ></CustomMultiselect>
            <CustomMultiselect
              style="width: 45%"
              v-if="verlinkung.typ === 'Reisetermin'"
              :class="
                verlinkung.artifactId === null ? 'invalid-custom-multiselect' : 'valid-custom-multiselect'
              "
              :show-labels="false"
              label="reiseterminkuerzel"
              track-by="id"
              @input="changeArtifactReisetermin($event, verlinkung.id)"
              @search-change="onSearchChangeReisetermin"
              :options="reiseterminkuerzelOptions"
              placeholder="Reiseterminsuche"
              removeClearButton
              :value="
                verlinkung.artifactId !== null
                  ? { kuerzelId: verlinkung.artifactId, reiseterminkuerzel: verlinkung.artifactText }
                  : null
              "
            ></CustomMultiselect>
            <b-form-input
              style="width: 45%"
              v-if="verlinkung.typ === 'Flugverfügbarkeit' || verlinkung.typ === 'Vorgang'"
              @input="changeArtifactFlugverfuegbarkeitOrVorgang($event, verlinkung.typ, verlinkung.id)"
              v-model="verlinkung.artifactId"
              type="number"
              :disabled="fromFVC"
              :state="verlinkung.artifactId > 0"
              placeholder="Gib eine Id ein..."
            ></b-form-input>
            <i
              v-if="!fromFVC"
              class="ml-auto fas fa-times text-danger align-self-center"
              @click="removeVerlinkung(verlinkung.id)"
              style="cursor: pointer"
            ></i>
          </div>

          <b-button
            class="mt-2"
            size="sm"
            variant="primary"
            @click="addVerlinkung"
            v-if="!disableAttachments"
          >
            +
          </b-button>
          <b-button
            class="mt-2 ml-4"
            size="sm"
            @click="showAllVerlinkungen = !showAllVerlinkungen"
            v-if="!showAllVerlinkungen && verlinkungen.length > 5"
          >
            Zeige alle ({{ verlinkungen.length }})
          </b-button>
          <b-button
            class="mt-2 ml-4"
            size="sm"
            @click="showAllVerlinkungen = !showAllVerlinkungen"
            v-else-if="verlinkungen.length > 5"
          >
            Zeige weniger
          </b-button>
          <b-form-checkbox
            v-if="enableManualTask"
            v-model="proTerminEineAufgabeChecked"
            switch
            class="mt-2"
            size="lg"
          >
            Pro Termin eine Aufgabe
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-overlay>

    <template #modal-footer="{ hide }" class="pl-1">
      <b-button
        class="mr-4"
        :disabled="disableSichernButton"
        :variant="disableSichernButton ? 'secondary' : 'success'"
        @click="handleSubmit(hide)"
      >
        Erstellen
      </b-button>
      <b-button @click="hide" :disabled="isBusy">Abbrechen</b-button>
    </template>
  </b-modal>
</template>

<script>
import { checkDateInFuture, permittedDepartments } from '@/core/common/helpers/utils.js';
import buildQuery from 'odata-query';
import { mapState, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { CREATE_AUFGABE } from '@/core/aufgaben/stores/aufgaben.module';
import Editor from '@/components/common/editor.vue';
import UsersMultiselect from '@/components/common/users-multiselect.vue';
import apiService from '@/core/common/services/api.service';
import CustomMultiselect from '@/components/common/custom-multiselect.vue';
import { format } from 'date-fns';
import { textbausteine } from '@/core/common/helpers/utils';
import { subBusinessDays } from 'date-fns';

export default {
  mixins: [validationMixin],
  name: 'AufgabeErstellenModal',
  components: { CustomMultiselect, Editor, UsersMultiselect },
  props: {
    prefixedVerlinkungen: {
      type: Array,
      default: () => {
        return [];
      },
    },
    disableAttachments: { type: Boolean, default: false },
    fromFVC: { type: Boolean, default: false },
    enableManualTask: { type: Boolean, default: false },
  },
  watch: {
    prefixedVerlinkungen(v) {
      this.verlinkungen = v;
      this.showAllVerlinkungen = false;
    },
    'form.kategorie'(newVal) {
      if (newVal === 'OptionsfristErreicht') {
        this.form.beschreibung = `<div> <li> ${textbausteine.toDosBeiGpr}</li> <li>${textbausteine.toDosBeiGruppe}</li> </div>`;
      } else if (newVal === 'ZimmerkontingentErhoehen') {
        this.form.beschreibung = textbausteine.zimmerkontingentePruefen;
      } else if (newVal === 'KurzfristigeFlugzeitenaenderung') {
        this.form.beschreibung = textbausteine.KurzfristigeFlugzeitenaenderung;
      } else if (newVal === 'ZusatzterminePruefen') {
        this.form.beschreibung = textbausteine.ZusatzterminePruefen;
      } else if (newVal === 'HotelwechselPruefen') {
        this.form.beschreibung = textbausteine.HotelwechselPruefen;
      } else if (!this.form.beschreibung) {
        this.form.beschreibung = '';
      }
    },
  },
  data() {
    return {
      mostContactedPeople: [],
      form: {
        faelligkeitsdatum: null,
        prioritaet: null,
        bearbeiter: null,
        beschreibung: null,
        kategorie: null,
        team: null,
      },
      activeEditor: false,
      userIdWebstada: 'a7dfc360-dd20-4f2d-a195-a47b4bcfa4b0',
      verlinkungen: [],
      verlinkungenTypOptions: ['Reisetermin', 'Flugverfügbarkeit', 'Vorgang', 'Reise'],
      error: {},
      prioritaetOptions: [
        { value: 0, text: 'Niedrig' },
        { value: 1, text: 'Mittel' },
        { value: 2, text: 'Hoch' },
      ],
      reisekuerzelOptions: [],
      reiseterminkuerzelOptions: [],
      timeoutReiseterminkuerzelSearch: null,
      showAllVerlinkungen: true,
      proTerminEineAufgabeChecked: false,
      isBeschreibungExpanded: false,
    };
  },
  mounted() {
    this.$v.form.$touch(); // Trigger validation on component mount
    this.getReisekuerzel();
    if (this.prefixedVerlinkungen?.length > 0) {
      this.verlinkungen = this.prefixedVerlinkungen;
    }
  },
  computed: {
    ...mapState({
      isBusy: state => state.aufgaben.isBusy,
      users: state => state.users['users'],
      kategorienConfig: state => state.stateConfig.kategorienConfig,
    }),
    ...mapGetters(['currentUserId', 'parsedRoles', 'getKategorienManuallyCreatable']),
    disableSichernButton() {
      return (
        this.$v.form.$anyError ||
        this.isBusy ||
        this.verlinkungen.some(verlinkung => verlinkung.artifactId === null)
      );
    },
    peopleWithRestrictedPermission() {
      return this.users?.filter(
        user => permittedDepartments.includes(user.department) || user.id === this.userIdWebstada
      );
    },
    isAdmin() {
      return this.parsedRoles.includes('Admin');
    },
    zugewiesenePersonOptions() {
      if (this.isAdmin) {
        return this.changePeopleToCorrectShape(this.users);
      } else {
        return this.changePeopleToCorrectShape(this.peopleWithRestrictedPermission) || [];
      }
    },
    teamOptions() {
      if (this.isAdmin) {
        return this.changeTeamsToCorrectShape(this.users);
      } else {
        return this.changeTeamsToCorrectShape(this.peopleWithRestrictedPermission) || [];
      }
    },
    kategorieOptions() {
      if (!this.getKategorienManuallyCreatable) return [];

      return this.getKategorienManuallyCreatable;
    },
    minFaelligkeitsdatum() {
      return new Date();
    },
    showVerlinkungen() {
      if (this.showAllVerlinkungen) {
        return this.verlinkungen;
      } else {
        return this.verlinkungen.slice(0, 5);
      }
    },
  },
  validations: {
    form: {
      faelligkeitsdatum: {
        required,
        checkDateInFuture,
      },
      prioritaet: {
        required,
      },
      beschreibung: {},
      bearbeiter: {
        required(v) {
          return this.form.team !== null || required(v);
        },
      },
      team: {
        required(v) {
          return this.form.bearbeiter !== null || required(v);
        },
      },
      kategorie: { required },
    },
  },
  methods: {
    changeTeamsToCorrectShape(data) {
      const duplicateDepartments = data
        .filter(({ givenName }) => givenName !== null && !givenName.toLowerCase().includes('test'))
        .filter(({ surname }) => surname !== null && !surname.toLowerCase().includes('test'))
        .filter(({ mail }) => mail !== null)
        .map(({ department }) => department);
      return [...new Set(duplicateDepartments)]
        .filter(dep => dep !== null)
        .map(department => ({ value: department, text: department }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    changePeopleToCorrectShape(data) {
      const bearbeiterOptions = data
        .filter(({ givenName }) => givenName !== null && !givenName.toLowerCase().includes('test'))
        .filter(({ surname }) => surname !== null && !surname.toLowerCase().includes('test'))
        .filter(({ mail }) => mail !== null)
        .map(({ givenName, surname, id }) => ({
          value: id,
          text: givenName + ' ' + surname,
        }));
      if (this.mostContactedPeople.length > 0) {
        const order = this.mostContactedPeople.reduce((acc, curr, idx) => {
          return { ...acc, [curr.bearbeiter]: idx + 1 };
        }, {});
        return bearbeiterOptions.sort((a, b) => {
          const orderA = order[a.value] || this.mostContactedPeople.length + 1;
          const orderB = order[b.value] || this.mostContactedPeople.length + 1;

          if (orderA !== orderB) {
            return orderA - orderB;
          } else {
            return a.text.localeCompare(b.text);
          }
        });
      } else return bearbeiterOptions;
    },
    debounce(fn, delay) {
      let timeoutID = null;
      return function () {
        clearTimeout(timeoutID);
        const args = arguments;
        const that = this;
        timeoutID = setTimeout(function () {
          fn.apply(that, args);
        }, delay);
      };
    },
    onSearchChangeReisetermin($event) {
      if ($event.length > 0) {
        if (this.timeoutReiseterminkuerzelSearch) {
          clearTimeout(this.timeoutReiseterminkuerzelSearch);
        }
        this.timeoutReiseterminkuerzelSearch = setTimeout(() => {
          apiService
            .get(
              `Reisetermin?$filter=startswith(reiseterminkuerzel,'${$event}') and abreisedatum ge ${format(
                new Date(),
                'yyyy-MM-dd'
              )}&$select=reiseterminkuerzel,id, unterlagenversandDatum`
            )
            .then(res => {
              const reisetermine = [...res.data.result.value] || [];
              this.reiseterminkuerzelOptions = reisetermine.map(reisetermin => {
                return {
                  reiseterminkuerzel: reisetermin.reiseterminkuerzel,
                  id: reisetermin.id,
                  unterlagenversandDatum: reisetermin.unterlagenversandDatum,
                };
              });
            });
        }, 500); // delay
      }
    },
    removeArtifacts(verlinkungId) {
      this.verlinkungen = this.verlinkungen.map(verlinkung => {
        if (verlinkung.id === verlinkungId) {
          return { ...verlinkung, artifactId: null, artifactText: null };
        } else return verlinkung;
      });
    },
    changeArtifactReisetermin($event, verlinkungId) {
      const { id, reiseterminkuerzel, unterlagenversandDatum } = $event;

      if (this.form.kategorie === 'HotelwechselPruefen') {
        this.changePriorityBasedOnReisetermin(unterlagenversandDatum);
      }

      const existingVerlinkung = this.verlinkungen.some(
        verlinkung => verlinkung.artifactId === id && verlinkung.typ === 'Reisetermin'
      );
      if (existingVerlinkung) {
        this.$bvToast.toast('Dieser Reisetermin wurde bereits verlinkt.', {
          title: 'Warnung',
          variant: 'warning',
          solid: true,
        });
        return;
      }

      this.verlinkungen = this.verlinkungen.map(verlinkung => {
        if (verlinkung.id === verlinkungId) {
          return {
            ...verlinkung,
            artifactId: id,
            artifactText: reiseterminkuerzel,
            unterlagenversandDatum: unterlagenversandDatum,
          };
        } else return verlinkung;
      });
    },
    changePriorityBasedOnReisetermin(unterlagenversandDatum) {
      let tageBisUnterlagenVersand = null;
      if (unterlagenversandDatum) {
        const versandDatum = new Date(unterlagenversandDatum);
        console.log('versandDatum: ', versandDatum);
        const heute = new Date();

        const tageUnterschied = versandDatum.getTime() - heute.getTime();
        tageBisUnterlagenVersand = Math.ceil(tageUnterschied / (1000 * 60 * 60 * 24));

        if (tageBisUnterlagenVersand <= 10) {
          this.form.prioritaet = 2;
        } else if (tageBisUnterlagenVersand <= 21) {
          this.form.prioritaet = 1;
        } else {
          this.form.prioritaet = 0;
        }
      }

      const faelligkeitsdatum = subBusinessDays(new Date(unterlagenversandDatum), 4);

      // formattiert es so, dass es für das form.faelligkeitsdatum passt
      this.form.faelligkeitsdatum = faelligkeitsdatum.toISOString().split('T')[0];
    },
    changeArtifactFlugverfuegbarkeitOrVorgang(input, verlinkungsTyp, verlinkungsId) {
      if (verlinkungsTyp === 'Vorgang') {
        const existingVorgang = this.verlinkungen.some(
          verlinkung =>
            verlinkung.artifactId == input &&
            verlinkung.typ === verlinkungsTyp &&
            verlinkung.id !== verlinkungsId
        );

        if (existingVorgang) {
          this.verlinkungen.find(verlinkung => verlinkung.id === verlinkungsId).artifactId = '';
          this.$bvToast.toast('Dieser Vorgang wurde bereits verlinkt.', {
            title: 'Warnung',
            variant: 'warning',
            solid: true,
          });
          return;
        }
        this.addReiseterminAutomatisch(input);
      }
    },
    addReiseterminAutomatisch(input) {
      if (input && input?.length === 7) {
        apiService
          .get(
            `/Reisetermin?$filter=((hotelleistungen/any(hotelleistungen:hotelleistungen/isDeleted eq false and hotelleistungen/vorgangId eq ${input})))&$select=reiseterminkuerzel, id`
          )
          .then(response => {
            if (response.status === 200) {
              // filtert über die liste der Reisetermine und behält nur die Einträge die noch nicht vorhanden sind
              const uniqueReisetermine = response.data.result.value.filter(
                item =>
                  // überprüft dann, ob es diesen Reisetermin mit der artifactId schon gibt
                  !this.verlinkungen.some(
                    verlinkung => verlinkung.artifactId === item.id && verlinkung.typ === 'Reisetermin'
                  )
              );
              if (uniqueReisetermine.length === 0) {
                this.$bvToast.toast('Der Reisetermin existiert bereits', {
                  title: 'Warnung',
                  variant: 'warning',
                  solid: true,
                });
              } else {
                const newVerlinkungen = response.data.result.value.map((item, i) => ({
                  id: this.verlinkungen.length + i,
                  typ: 'Reisetermin',
                  artifactId: item.id,
                  artifactText: item.reiseterminkuerzel,
                }));

                console.log('newVer: ', newVerlinkungen);
                this.verlinkungen = [...this.verlinkungen, ...newVerlinkungen];
              }
            }
          });
      }
    },
    changeArtifactReise($event, verlinkungId) {
      const { id, reisekuerzel } = $event;

      const existingReise = this.verlinkungen.some(
        verlinkung => verlinkung.artifactId === id && verlinkung.typ === 'Reise'
      );
      if (existingReise) {
        this.$bvToast.toast('Diese Reise wurde bereits verlinkt.', {
          title: 'Warnung',
          variant: 'warning',
          solid: true,
        });
        return;
      }

      this.verlinkungen = this.verlinkungen.map(verlinkung => {
        if (verlinkung.id === verlinkungId) {
          return { ...verlinkung, artifactId: id, artifactText: reisekuerzel };
        } else return verlinkung;
      });
    },
    async getReisekuerzel() {
      let query = buildQuery({
        select: ['reisekuerzel', 'id'],
      });
      this.reisekuerzelOptions = await apiService
        .get('Reise' + query)
        .then(res => {
          return res?.data?.result?.value;
        })
        .catch(err => {
          console.err(err);
        });
    },
    addVerlinkung() {
      const nextId =
        this.verlinkungen.length > 0 ? this.verlinkungen[this.verlinkungen.length - 1].id + 1 : 0;

      const existingVerlinkung = this.verlinkungen.some(verlinkung => {
        return (
          (verlinkung.artifactId === this.form.artifactId && verlinkung.typ === this.form.typ) ||
          (verlinkung.artifactText && verlinkung.artifactText === this.form.artifactText)
        );
      });

      if (existingVerlinkung) {
        // Zeige eine Warnung an, wenn der Vorgang/Reisetermin/Reise bereits hinzugefügt wurde
        this.$bvToast.toast('Dieser Vorgang, Reisetermin oder Reise wurde bereits verlinkt.', {
          title: 'Warnung',
          variant: 'warning',
          solid: true,
        });
        return;
      }

      this.verlinkungen = [
        ...this.verlinkungen,
        { id: nextId, typ: null, artifactId: null, artifactText: null },
      ];
    },
    removeVerlinkung(id) {
      this.verlinkungen = this.verlinkungen.filter(verlinkung => verlinkung.id !== id);
    },
    resetFields() {
      this.form = {
        faelligkeitsdatum: null,
        prioritaet: null,
        bearbeiter: null,
        beschreibung: null,
        kategorie: null,
        team: null,
      };
      this.verlinkungen = [];
      this.showAllVerlinkungen = false;
      this.activeEditor = false;
      this.proTerminEineAufgabeChecked = false;
      this.isBeschreibungExpanded = false;
    },
    resetPersonOderTeam() {
      this.form = { ...this.form, bearbeiter: null, team: null };
    },
    resetPrioritaet() {
      this.form = { ...this.form, prioritaet: null };
    },
    resetFaelligkeitsdatum() {
      this.form = { ...this.form, faelligkeitsdatum: null };
    },
    resetKategorie() {
      this.form = { ...this.form, kategorie: null };
      this.form.beschreibung = null;
    },
    async handleSubmit(hide) {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let params = { ...this.$v.form.$model, kategorie: this.$v.form.$model.kategorie };

      const vorgangVerlinkungen = this.verlinkungen.filter(verlinkungen => verlinkungen.typ === 'Vorgang');
      const reiseterminVerlinkungen = this.verlinkungen.filter(
        verlinkungen => verlinkungen.typ === 'Reisetermin'
      );
      const reiseVerlinkungen = this.verlinkungen.filter(verlinkungen => verlinkungen.typ === 'Reise');
      const flugverfuegbarkeitVerlinkungen = this.verlinkungen.filter(
        verlinkungen => verlinkungen.typ === 'Flugverfügbarkeit'
      );
      if (this.verlinkungen.length > 0) {
        const verlinkungenFormatted = {
          ...(vorgangVerlinkungen.length > 0 && {
            vorgaenge: vorgangVerlinkungen.map(verlinkung => parseInt(verlinkung.artifactId)),
          }),
          ...(reiseterminVerlinkungen.length > 0 && {
            reiseTermine: reiseterminVerlinkungen.map(verlinkung => parseInt(verlinkung.artifactId)),
          }),
          ...(reiseVerlinkungen.length > 0 && {
            reisen: reiseVerlinkungen.map(verlinkung => parseInt(verlinkung.artifactId)),
          }),
          ...(flugverfuegbarkeitVerlinkungen.length > 0 && {
            flugverfuegbarkeiten: flugverfuegbarkeitVerlinkungen.map(verlinkung =>
              parseInt(verlinkung.artifactId)
            ),
          }),
        };
        params = {
          ...params,
          ...verlinkungenFormatted,
        };
      }

      if (this.proTerminEineAufgabeChecked) {
        // Für jeden Reisetermin eine eigene Aufgabe erstellen
        for (const verlinkung of reiseterminVerlinkungen) {
          const paramsFuerEinzelneAufgabe = {
            ...params,
            reiseTermine: [parseInt(verlinkung.artifactId)],
          };

          // für jeden reisetermin eine aufgabe erstellen
          await this.$store.dispatch(CREATE_AUFGABE, paramsFuerEinzelneAufgabe);
        }

        for (const flugverfuegbarkeitVerlinkung of flugverfuegbarkeitVerlinkungen) {
          const paramsFuerEinzelneAufgabe = {
            ...params,
            flugverfuegbarkeiten: [parseInt(flugverfuegbarkeitVerlinkung.artifactId)],
          };

          // für jeden flugverfügbarkeit eine aufgabe erstellen
          await this.$store.dispatch(CREATE_AUFGABE, paramsFuerEinzelneAufgabe);
        }

        // damit gesagt wird, dass mehrere Aufgaben erstellt werden
        this.$emit('aufgabeErstellt', 'bulk');
      } else {
        // Eine Aufgabe erstellen und Erfolgsbenachrichtigung anzeigen
        await this.$store.dispatch(CREATE_AUFGABE, params).then(() => this.toast('Aufgabe wurde erstellt.'));

        if (flugverfuegbarkeitVerlinkungen.length > 1) {
          this.$emit('aufgabeErstellt', 'bulk');
        } else if (flugverfuegbarkeitVerlinkungen.length === 1) {
          this.$emit('aufgabeErstellt', 'single');
        } else {
          this.$emit('aufgabeErstellt');
        }
      }
      hide();
    },
    onShow() {
      this.loadMostContactedEditors();
      setTimeout(() => {
        this.activeEditor = true;
      }, 50);
    },
    loadMostContactedEditors() {
      const mostContactedQuery = `/aufgaben/?apply=filter(autor eq '${this.currentUserId}')/groupby((bearbeiter),aggregate($count as count))`;
      apiService.get(mostContactedQuery).then(resp => {
        const result = resp.data.result.value;
        const sortedResult = result.sort((a, b) => b.count - a.count);
        this.mostContactedPeople = sortedResult;
      });
    },
    toggleBeschreibungsFeldSize() {
      this.isBeschreibungExpanded = !this.isBeschreibungExpanded;
    },
  },
};
</script>
<style scoped>
.placeholder-multiselect {
  color: #b5b5c3;
}

:deep(.multiselect) {
  min-height: 30px;
}

:deep(.multiselect__tags) {
  height: 36.39px;
  box-sizing: border-box;
}

.invalid-custom-multiselect {
  border: 1px #f64e60 solid;
  border-radius: 5px;
}

.valid-custom-multiselect {
  border: 1px #1bc5bd solid;
  border-radius: 5px;
}

:deep(label[for='bearbeiter']::after),
:deep(label[for='prioritaet']::after),
:deep(label[for='kategorie']::after),
:deep(label[for='datepicker']::after),
:deep(label[for='team']::after) {
  content: '*';
  color: #f64e60;
}

.beschreibung-expanded {
  position: fixed;
  top: 50px;
  left: 50px;
  right: 50px;
  bottom: 50px;
  z-index: 1050;
  background: white;
  padding: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
